import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 *
 * @param {boolean} visible - Cookie popup visible or not
 * @param {function} acceptCookies - Accept cookies
 * @param {function} declineCookies - Decline cookies
 * @return {JSX.element} ...
 */
export default function CookiesModal({
  visible,
  acceptCookies,
  declineCookies
}) {
  const {t} = useTranslation('cookie_policy');

  return (
    <>
      { visible && (
        <div className="cookies">
          <div className="row">
            <div className="col-12">
              <h3>{t('title')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Thegameispon.com gebruikt cookies om bijvoorbeeld de website te verbeteren en te analyseren. Door op ‘Akkoord’ te klikken geef je Pon toestemming voor het gebruik van noodzakelijke en optimale cookies op onze website. Als je meer wilt weten over hoe wij met jouw persoonlijke gegevens omgaan, kun je de <Link to='/privacy-statement'>Privacyverklaring</Link> en het <Link to='/cookie-policy'>Cookiebeleid</Link> raadplegen.</p>
              <button
                className="btn btn-dark"
                onClick={ () => acceptCookies() }>
                  {t('button_accept')}
              </button>
              <button
                className="btn btn-link p-0"
                onClick={ () => declineCookies() }>
                  {t('button_decline')}
              </button>
            </div>
          </div>
        </div>
      ) }
    </>
  );
}
