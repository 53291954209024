import React, { useEffect, useState } from 'react';

/**
 * Quiz header
 *
 * @param {object} questionData - The question data
 *
 * @return {JSX.Element} Quiz header component
 */
export default function QuizQuestion({
  questionData,
  changeAnswer,
  quizFinished,
  quizExpired,
  selectedAnswers,
  showAnswer,
}) {
  const { title, image, question, id, answers } = questionData;
  const [givenAnswer, setGivenAnswer] = useState({});
  const correctAnswer = answers.findIndex((obj) => obj.points === 1);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    const answerIndex = selectedAnswers.findIndex((obj) => obj.question === id);

    if (answerIndex >= 0) {
      setGivenAnswer(selectedAnswers[answerIndex]);
    }
  }, [selectedAnswers, id]);

  useEffect(() => {
    // Make sure we see the explanation when we go to the previous question.
    if (givenAnswer?.answer !== undefined) {
      setSelectedIndex(givenAnswer?.answer);
    }
  }, [givenAnswer, selectedIndex]);

  return (
    <section className="quiz__question">
      <div className="col-12 col-md-10 offset-md-1">
        {image && <img className="img-fluid" src={image} alt="question" />}
      </div>
      <div className="col-12 col-md-10 offset-md-1">
        <h2 className="quiz__question--title">{title}</h2>
        <p>{question}</p>
        {!quizFinished &&
          !quizExpired &&
          answers.map((answer, i) => {
            return (
              <div key={i} className="col-12 col-md-6 offset-md-3">
                <button
                  className={`button answer
                    ${givenAnswer?.answer === i ? 'active' : ''}
                    ${showAnswer ? 'answered' : ''}
                    ${
                      showAnswer &&
                      correctAnswer === i &&
                      Object.keys(givenAnswer).length !== 0
                        ? 'correct-answer'
                        : ''
                    }
                    ${
                      showAnswer &&
                      correctAnswer !== i &&
                      Object.keys(givenAnswer).length !== 0
                        ? 'wrong-answer'
                        : ''
                    }
                  `}
                  disabled={showAnswer}
                  onClick={() => {
                    changeAnswer(i, id, correctAnswer);
                    setSelectedIndex(i);
                  }}
                >
                  {answer.text}
                </button>
              </div>
            );
          })}
        {Object.keys(givenAnswer).length !== 0 && showAnswer && (
          <div className="col-12">
            <p className="explanation">
              {questionData?.answers[selectedIndex]?.explanation}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
