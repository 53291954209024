import { useEffect, useState } from 'react';
import { fetchQuestionsFromAPI } from './../../dao/quiz';

let quizCache = [];
/**
 * Basic hook to fetch posts from the server and cache it in state.
 *
 * @param {string} dayOfQuiz - Quiz day
 * 
 * @return {array} Quiz state
 */
export default function useQuiz(dayOfQuiz) {
    const [ quiz, setQuiz ] = useState(quizCache);

    useEffect(() => {
        fetchQuestionsFromAPI(dayOfQuiz)
            .then((quiz) => quizCache = quiz)
            .then((quiz) => setQuiz(quiz))
        ;
    }, [dayOfQuiz]);

    return [ quiz ];
}
