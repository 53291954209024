import { fetchJSONFromAPI } from './api';

const API_ENDPOINT = '/pages';

/**
 * Fetch a single page by slug
 *
 * @param {string} slug The unique slug of the page
 * @param {string} language The language
 * @return {object} resolves to page.
 */
export async function fetchPageBySlug(slug, language) {
  const pages = await fetchJSONFromAPI(API_ENDPOINT, {
    _embed: 'wp:featuredmedia',
    slug: slug,
    lang: language,
  });

  return pages.shift(); // Take the first page of the top
}
