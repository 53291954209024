import ReactGA from 'react-ga';

export const initGA = (id) => {
  ReactGA.initialize([
    {
      trackingId: id,
      gaOptions: {
        anonymizeIp: true
      }
    }
  ]);
};
