import React from 'react';

/**
 * Progress bar
 *
 * @param {int} currentQuestion - Current question index
 * @param {Array} questions - Array of questions
 *
 * @return {JSX.Element} Progress bar
 */
export default function ProgressBar({ currentQuestion, questions }) {
  return (
    <div className="quiz__steps">
      {questions.map((question, i) => {
        return (
          <div
            key={i}
            className={`quiz__steps--progress ${
              currentQuestion >= i ? 'active' : ''
            }`}
          />
        );
      })}
    </div>
  );
}
