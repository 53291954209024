import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @return {JSX.Element}
 */
export default function LanguageSwitch() {
  const { i18n } = useTranslation();
  const { t } = useTranslation('languageSwitch');

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul className="language-switch">
            <li>
              <a
                className={`language-switch__button ${
                  i18n.language === 'nl' ? 'active' : ''
                }`}
                href="/nl/home"
              >
                {t('dutch')}
              </a>
            </li>
            <li>
              <a
                className={`language-switch__button ${
                  i18n.language === 'en' ? 'active' : ''
                }`}
                href="/en/home"
              >
                {t('english')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
