import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initGA } from '../ga-utils';
import CookiesModal from './general/Cookies';
import { Cookies } from 'react-cookie';
import ScrollToTop from '../Contexts/ScrollToTop';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import WordPressPage from '../pages/WordPressPage';
import { setCookies } from '../helpers/cookies';
import { HelmetProvider } from 'react-helmet-async';
import PageNotFound from '../pages/PageNotFound';
import {
  LanguageDetector,
  determineKnownLocale,
} from './general/LanguageDetector';
import LanguageSwitch from './general/LanguageSwitch';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import { SecureRoute } from '../auth/SecureRoute';
import { oktaAuth } from '../auth/oktaAuth';

/**
 * This is the main entry-point into the app.
 *
 * @return {JSX.Element} The app container
 */
function App() {
  const cookies = new Cookies();
  const hasSetCookiePreferences = cookies.get('gameHasPreferences');
  const hasCookiesAccepted = cookies.get('gameAcceptCookies');
  const [cookiesVisible, setCookiesVisible] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const base = determineKnownLocale(i18n.language);

  useEffect(() => {
    checkCookies();
  });
  /**
   * Check if cookies are already set
   */
  function checkCookies() {
    if (typeof hasSetCookiePreferences === 'undefined') {
      setCookiesVisible(true);
    }

    if (hasCookiesAccepted) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }

  /**
   * Accept cookies
   */
  function acceptCookies() {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    setCookiesVisible(false);
    setCookies(true);
  }

  /**
   * Decline cookies
   */
  function declineCookies() {
    setCookiesVisible(false);
    setCookies(false);
  }

  /**
   * Show Cookie Banner
   */
  function showCookiePreferences() {
    setCookiesVisible(true);
  }

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div id="app" className="App">
        <HelmetProvider>
          <ScrollToTop />
          <Header />
          <div className="csr-background">
            <div className="csr-background__content">
              <LanguageSwitch />
              <Routes>
                <Route path="implicit/callback" element={<LoginCallback />} />
                <Route path="/" element={<LanguageDetector />} />
                <Route path={`/${base}/home`} element={<SecureRoute />}>
                  <Route path="" element={<Home />} />
                </Route>
                <Route
                  path={`${base}/terms-and-conditions`}
                  element={WordPressPage}
                />
                <Route path={`${base}/cookie-policy`} element={WordPressPage} />
                <Route
                  path={`${base}/privacy-statement`}
                  Component={WordPressPage}
                />
                <Route path={`${base}/*`} element={<PageNotFound />} />
              </Routes>
              <Footer showCookiePreferences={showCookiePreferences} />
            </div>
          </div>
          <CookiesModal
            visible={cookiesVisible}
            acceptCookies={acceptCookies}
            declineCookies={declineCookies}
          />
        </HelmetProvider>
      </div>
    </Security>
  );
}

export default App;
