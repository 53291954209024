import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

/**
 * Detector for i18next-browser-languageDetector which only accepts a known
 * locale at the start of the URL. If anything else is found, it is ignored
 * and up to the other detectors to determine the best language.
 */
const DetectKnowLocaleFromURL = {
  name: 'path-known-lang',
  lookup(options) {
    if (typeof window !== 'undefined') {
      // Split by forward slash and question mark, should result in a clean
      // locale after filtering out empty results
      const [language] = window.location.pathname
        .split(/[/?]/)
        .filter((n) => n);
      return options.knownLocales?.includes(language) ? language : undefined;
    }
  },
};

const browserLanguageDetector = new BrowserLanguageDetector();
browserLanguageDetector.addDetector(DetectKnowLocaleFromURL);

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(browserLanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'nl',
    debug: false,
    detection: {
      order: ['querystring', 'path'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged',
    },
  });

export default i18n;
