import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import Quiz from '../Components/quiz/Quiz';
import { QUIZ_SLUG } from '../Constants';
import useQuiz from '../Components/quiz/useQuiz';
import { saveScoreInDatabase } from '../dao/quiz';
import { fetchPageBySlug } from '../dao/pages';
import { determineKnownLocale } from './general/LanguageDetector';

/**
 * Home
 * @return {JSX.element} ...
 */
export default function Home() {
  const quizExpired = false;
  const daySlug = QUIZ_SLUG;

  const { i18n } = useTranslation();
  const language = determineKnownLocale(i18n.language);
  const { t } = useTranslation('quiz');

  const [quizFinished, setQuizFinished] = useState(false);
  const [noAnswers, setNoAnswers] = useState(false);
  const [notAllQuestionsAnswered, setNotAllQuestionsAnswered] = useState(false);
  const [errors, setErrors] = useState([]);
  const [page, setPage] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);

  const [questions] = useQuiz(`${daySlug}-${language}`);

  useEffect(() => {
    const fetchPage = async (slug) => {
      try {
        setPage(await fetchPageBySlug(slug, language));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };

    // Fetch WordPress page data for slug home
    fetchPage('home');
  }, [language]);

  /**
   * Submit the quiz, used in the Quiz component
   *
   * @param {array} selectedAnswers - The selected answers
   */
  async function submitQuiz(selectedAnswers) {
    setErrors('');

    // Reset states
    setNoAnswers(false);
    setNotAllQuestionsAnswered(false);

    if (!selectedAnswers.length) {
      setNoAnswers(true);
    } else if (selectedAnswers.length !== questions.length) {
      setNotAllQuestionsAnswered(true);
    } else {
      saveScoreInDatabase(selectedAnswers, QUIZ_SLUG).then(() => {
        setNoAnswers(false);
        setQuizFinished(true);

        let correctAnswerCount = 0;
        selectedAnswers.forEach((selectedAnswer) => {
          if (selectedAnswer.answer === selectedAnswer.correctAnswerIndex) {
            correctAnswerCount++;
          }
        });

        setCorrectAnswers(correctAnswerCount);
      });

      // Send a event to Google Analytics
      ReactGA.event({
        category: 'Quiz',
        action: 'Successful form submission',
      });
    }
  }

  return (
    <div className="quiz-day">
      {page && !quizStarted && (
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 offset-lg-1">
              <div className="page-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered,
                  }}
                />
                <button
                  className="button button--pagination button--start"
                  onClick={() => {
                    setQuizStarted(true);
                  }}
                >
                  {t('startLearning')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {quizStarted && (
        <Quiz
          questions={questions}
          submitQuiz={submitQuiz}
          noAnswers={noAnswers}
          quizFinished={quizFinished}
          quizExpired={quizExpired}
          notAllQuestionsAnswered={notAllQuestionsAnswered}
          errors={errors}
          correctAnswers={correctAnswers}
          confirmationData={page?.acf}
        />
      )}
    </div>
  );
}
