import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Quiz header
 *
 * @param {object} afterQuestionContent - The content after the question
 *
 * @return {JSX.Element} Quiz header component
 */
export default function AfterQuestion({
  afterQuestionContent,
  image,
  previousQuestion,
  questionShown,
}) {
  const { t } = useTranslation('quiz');

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
          <div
            className="quiz__after-question quiz__wrapper"
            style={
              image
                ? {
                    backgroundImage: `url(${image})`,
                  }
                : {}
            }
          >
            <div className="quiz__wrapper--content">
              {questionShown !== 0 && (
                <button
                  className="previous-button"
                  onClick={() => previousQuestion()}
                >
                  <img
                    src="/arrow-back.svg"
                    alt="redirect to previous question"
                  />
                  {t('previous')}
                </button>
              )}
              <div
                className={`quiz__after-question--content`}
                dangerouslySetInnerHTML={{
                  __html: afterQuestionContent,
                }}
              />
              <div className="quiz__after-question--scroll">
                <a href="#question-block" className="scroll-link">
                  {t('scrollToQuestion')}
                  <img src="/arrow-down.svg" alt="scroll to question" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
