import React from 'react';
import { Link } from 'react-router-dom';
import DefaultContentPage from './DeafultContentPage';
import { useTranslation } from 'react-i18next';

/**
 * @return {JSX.Element}
 */
export default function PageNotFound() {
    const {t} = useTranslation('page_not_found');
    return (
        <DefaultContentPage title={t('page_not_found_title')}>
            <div className="not-found">
                <p>{t('page_not_found_content')}</p>
                <p>{t('go-back')}</p>
                <Link to="/" className="button">{t('home')}</Link>
            </div>
        </DefaultContentPage>

    )
}
