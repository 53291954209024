import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchPageBySlug } from '../dao/pages';
import DefaultContentPage from './DeafultContentPage'

/**
 *
 * Display a page from the WordPress API
 *
 * @return {JSX.Element} - WordPress page content
 */
export default function WordPressPage() {
    const { i18n } = useTranslation();
    const slug = window.location.pathname;

    const [page, setPage] = useState(null)
    const fetchPage = async () => {
        try {
            setPage(await fetchPageBySlug(slug, i18n.language));
        } catch (e){
          // eslint-disable-next-line no-console
          console.log(e);
        }
    }

    useEffect(()=>{
        fetchPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])


    return (
        <DefaultContentPage title={page?.title?.rendered}>
            {page && <article
                dangerouslySetInnerHTML={ {
                    __html: page?.content?.rendered,
                    } }
                className={ `landingPage` }
            />}
        </DefaultContentPage>
    );
}
