import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Header component
 *
 * @return {JSX.Element} Header component
 */
export default function Header() {
  const { t } = useTranslation('header');

  return (
    <header>
      <section
        style={{
          backgroundImage: `url(/csr-e-learning.jpg)`,
        }}
      >
        <div className="image-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img width="105" height="24" src="/pon-logo.svg" alt="Pon" />
              </div>
              <div className="col-12">
                <div className="image-wrapper__title">
                  <h1>{t('title')}</h1>
                  <h1>{t('sub_title')}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
}
