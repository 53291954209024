import { QUIZ_API } from '../Constants';
import { firestore } from '../firebase';

/**
 * Fetch a set of questions by slug
 *
 * @param {string} slug The unique slug of the set
 * @return {object[]} questions.
 */
export async function fetchQuestionsFromAPI(slug) {
  const apiURL = `${QUIZ_API}/${slug}`;

  return fetch(apiURL)
      .then((response) => {
        if (response.status >= 400) {
          throw (response);
        }
        return response;
      })
      .then((response) => response.json());
}

/**
 * Fetch a set of questions by slug
 *
 * @param {string} slug The unique slug of the set
 * @param {object} answers The answers object
 * @return {object[]} questions.
 */
 export function postAnswersToAPI(slug, answers) {
  const apiURL = `${QUIZ_API}/${slug}`;

  return fetch(apiURL, {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(answers),
  })
      .then((response) => {
        if (response.status >= 400) {
          throw (response);
        }
        return response;
      })
      .then((response) => response.json());
}

/**
 * Check whether the selected answers are correct/
 *
 * @param {array} selectedAnswers - The selected answers
 */
export async function checkAnswers(selectedAnswers) {
  try {
      const response = await postAnswersToAPI('result', selectedAnswers )
      return response;
  } catch (e){
    // eslint-disable-next-line no-console
    console.log(e);
    return false;
  }
}

/**
 * @param {Object} answers - The selected answers
 * @param {String} slug - The quiz slug
 */
export async function saveScoreInDatabase(answers, slug) {
  const quizAnswers = await checkAnswers(answers);

  const nowDate = new Date();
  const date = nowDate.toLocaleString("nl-nl", {day: 'numeric', month: 'numeric', year: 'numeric'});
  const timestamp = nowDate.toLocaleString("nl-nl", {hour: '2-digit', minute: '2-digit', second: "2-digit"});
  const timestampString = nowDate.toTimeString();

  if (quizAnswers) {
    try {
      await firestore.collection(slug).doc().set({
        questionsTotal: quizAnswers?.top,
        questionsCorrect: quizAnswers?.score,
        givenAnswers: answers,
        date: date,
        timestamp: timestamp,
        timestampString: timestampString,
      });
    } catch (e) {
      return false;
    }
  }
}

/**
 * @param {string} email
 * @param {string} slug 
 */
export async function checkQuizSubmitted(email, slug) {
  let alreadySubmitted = false;

  if (email) {
      const doc = await firestore
          .collection(slug)
          .doc(email).get();
      if (doc.exists) {
        alreadySubmitted = true;
      }
  }

  return alreadySubmitted;
}
