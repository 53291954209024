import React from 'react';

/**
 * @param {string} title
 * @param {object} children
 * 
 * @return {JSX.Element}
 */
export default function DefaultContentPage({title, children}) {
    return (
        <article>
            <div className="container">
                <div className="row">
                    <div className='titlePage col-12 col-md-10 offset-md-1'>
                        <h1>{title}</h1>
                    </div>
                    <div className='contentPage col-12 col-md-10 offset-md-1'>
                        {children}
                    </div>
                </div>
            </div>
        </article>
    )
}
