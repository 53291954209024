import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/**
 * Footer
 *
 * @return {JSX.Element} Footer component
 */
export default function Footer({ showCookiePreferences }) {
  const {t} = useTranslation('footer')

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 text-center">
            <p>
              <a href="https://pon.com" title="Pon Holding site" target="_blank" rel="noopener noreferrer">
                <img width="70" height="16" src="/pon-logo.svg" alt="Pon" />
              </a>
              &copy; {new Date().getFullYear()} Pon. All rights reserved
            </p>
            <nav>
              <Link target="_blank" rel="noopener noreferrer" to='/cookie-policy'>{t('cookie_link')}</Link>
              <Link target="_blank" rel="noopener noreferrer" to='/privacy-statement'>{t('privacy_link')}</Link>
              <a href='#0' onClick={ () => showCookiePreferences() }>{t('cookie_settings_link')}</a>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
