import React, { useState, useEffect } from 'react';
import AfterQuestion from './AfterQuestion';
import QuizQuestion from './QuizQuestion';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../ProgressBar';

/**
 * Quiz
 *
 * @param {object} questions - The quiz data
 * @param {function} submitQuiz - Submit the quiz
 * @param {boolean} quizFinished - Whether the quiz has already been finished before
 * @param {object} errors - Contains error messages when there are errors
 *
 * @return {JSX.Element} Quiz component
 */
export default function Quiz({
  questions,
  submitQuiz,
  noAnswers,
  quizFinished,
  quizExpired,
  notAllQuestionsAnswered,
  errors,
  correctAnswers,
  confirmationData,
}) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [questionShown, setQuestionShown] = useState(0);
  const [questionCount, setQuestionCount] = useState(
    questions ? questions.length : 0
  );
  const [showAnswer, setShowAnswer] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const { t } = useTranslation('quiz');

  /**
   * @param {number} index - The answer index
   * @param {number} id - The question id
   * @param {number} correctAnswer - The correct answer index
   */
  function changeAnswer(index, id, correctAnswer) {
    const answers = [...selectedAnswers];
    const answersIndex = answers.findIndex((obj) => obj.question === id);

    if (answersIndex < 0) {
      answers.push({
        question: id,
        answer: index,
        correctAnswerIndex: correctAnswer,
      });
    } else {
      answers[answersIndex].answer = index;
      answers[answersIndex].correctAnswerIndex = correctAnswer;
    }

    setSelectedAnswers(answers);
  }

  /**
   * Redirect to the previous question.
   */
  function previousQuestion() {
    setQuestionShown((prevState) => prevState - 1);
    setShowAnswer(true);
    setShowSubmit(false);
  }

  useEffect(() => {
    if (questions) {
      setQuestionCount(questions.length);
    }
  }, [questions]);

  return (
    <div className="quiz">
      <div className="container">
        <div className="row">
          {quizFinished && (
            // Will be shown directly after submit
            <>
              <div className="col-12 col-lg-5 offset-lg-1">
                <div className="page-content">
                  <div
                    className="page-content__wrapper"
                    dangerouslySetInnerHTML={{
                      __html: confirmationData?.confirmation,
                    }}
                  />
                  {confirmationData.button_url &&
                    confirmationData.button_title && (
                      <a
                        href={confirmationData.button_url}
                        alt={confirmationData.button_title}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button button--pagination confirmation"
                      >
                        {confirmationData.button_title}
                      </a>
                    )}
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="score">
                  <h3>{t('score')}</h3>
                  <h3>{correctAnswers}</h3>
                  <hr className="score__border" />
                  <h3>{questions.length}</h3>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {questions.length && !quizFinished && (
        <div key={questions[questionShown]?.id}>
          {questions[questionShown]?.content && (
            <AfterQuestion
              afterQuestionContent={questions[questionShown]?.content}
              image={questions[questionShown]?.after_question_image}
              previousQuestion={previousQuestion}
              questionShown={questionShown}
            />
          )}
          <div className="container" id="question-block">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div className="quiz__wrapper">
                  <div className="quiz__wrapper--content">
                    <ProgressBar
                      currentQuestion={questionShown}
                      questions={questions}
                    />
                    <QuizQuestion
                      questionData={questions[questionShown]}
                      changeAnswer={changeAnswer}
                      quizFinished={quizFinished}
                      quizExpired={quizExpired}
                      selectedAnswers={selectedAnswers}
                      showAnswer={showAnswer}
                    />
                    <div className="button-wrapper">
                      {questionShown !== 0 && (
                        <button
                          className="button previous"
                          onClick={() => {
                            previousQuestion();
                            setShowAnswer(true);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {t('previous')}
                        </button>
                      )}
                      {!showSubmit ? (
                        <button
                          className={`button next ${
                            questionShown + 1 > selectedAnswers.length
                              ? 'hide-button'
                              : ''
                          }`}
                          onClick={() => {
                            if (showAnswer) {
                              setQuestionShown((prevState) => prevState + 1);

                              // If the next question was already answered, show the answer.
                              if (
                                questionShown + 2 ===
                                selectedAnswers.length
                              ) {
                                setShowAnswer(true);
                              } else {
                                setShowAnswer(false);
                                window.scrollTo(0, 0);
                              }
                            } else {
                              setShowAnswer(true);

                              // Show the submit button if all questions are answered.
                              if (questionCount === selectedAnswers.length) {
                                setShowSubmit(true);
                              }
                            }
                          }}
                        >
                          {showAnswer ? (
                            <>{t('next')}</>
                          ) : (
                            <>{t('submitAnswer')}</>
                          )}
                        </button>
                      ) : (
                        <button
                          className="button next"
                          onClick={() => {
                            submitQuiz(selectedAnswers);
                          }}
                          disabled={quizFinished}
                        >
                          {t('submitQuiz')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
