import { CORE_API, META_API, NAV_API } from '../Constants';

/**
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @return {URLSearchParams} The input as search params
 */
function convertJSONToSearchParams(parameters = {}) {
  const lang = 'nl';
  const params = {
    lang, // Set lang first, allows override from callee
    ...parameters,
  };
  return Object.keys(params).reduce(
      function convertToParam(acc, key) {
        acc.append(key, params[key]);
        return acc;
      },
      new URLSearchParams(),
  );
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object (NYI)
 * @return {Promise<any>} Resolves to the response
 */
function fetchFromBackend(apiRoot, endPoint, parameters, options) {
  const params = convertJSONToSearchParams(parameters);
  return fetch(`${apiRoot}${endPoint}?${params.toString()}`, {

  });
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<Response>} Resolves to the response
 */
export function fetchFromAPI(endPoint, parameters, options) {
  return fetchFromBackend(CORE_API, endPoint, parameters, options);
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<Response>} Resolves to the JSON of the response
 */
function fetchJSONFromBackend(apiRoot, endPoint, parameters, options) {
  return fetchFromBackend(apiRoot, endPoint, parameters, options)
      .then((response) => response.json())
  ;
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<any>} Resolves to the JSON of the response
 */
export function fetchJSONFromAPI(endPoint, parameters, options) {
  return fetchJSONFromBackend(CORE_API, endPoint, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<any>} Resolves to the JSON of the response
 */
export function fetchJSONFromMeta(endPoint, parameters, options) {
  return fetchJSONFromBackend(META_API, endPoint, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {string} location location to add to the request
 * @return {Promise<any>} Resolves to the JSON of the response
 */
export function fetchNavFromAPI(endPoint, location) {
  const apiURL = `${NAV_API}${endPoint}/${location}`;
  return fetch(apiURL)
      .then((response) => {
        if (response.status >= 400) {
          throw (response);
        }
        return response;
      })
      .then((response) => response.json());
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<any>} Resolves to the JSON of the response
 */
async function postJSONToBackend(
    apiRoot,
    endPoint,
    data,
    parameters = {},
    options = {},
) {
  const params = convertJSONToSearchParams(parameters);
  const allOptions = {
    method: 'POST',
    headers: {},
    body: JSON.stringify(data),
    ...options,
  };

  if (!allOptions.headers['Content-Type']) {
    allOptions.headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(
      `${apiRoot}${endPoint}?${params.toString()}`,
      allOptions,
  );
  return response.json();
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<any>} Resolves to the JSON of the response
 */
export async function postJSONToAPI(endPoint, data, parameters = {}, options) {
  return postJSONToBackend(CORE_API, endPoint, data, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @return {Promise<any>} Resolves to the JSON of the response
 */
export async function postJSONToMeta(endPoint, data, parameters = {}, options) {
  return postJSONToBackend(META_API, endPoint, data, parameters, options);
}
