import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

const locales = ['nl', 'en'];

/**
 * @param {string} locale Locale as a string (ll-RR)
 * @return {string} Closest matching locale known to this application
 */
export function determineKnownLocale(locale) {
  const fullMatch = locales.find(
    (knownLocale) => knownLocale.toLowerCase() === locale?.toLowerCase()
  );
  if (fullMatch) {
    // Language was matched perfectly.
    return fullMatch;
  }
  const languageMatch = locales.find((knownLocale) => {
    const lcKnown = knownLocale.toLowerCase();
    const lcLocale = locale?.toLowerCase();
    return lcKnown.startsWith(lcLocale) || lcLocale?.startsWith(lcKnown);
  });
  if (languageMatch) {
    // Language was matched aproximately.
    return languageMatch;
  }

  // Locale not detected.
  return locales[0];
}

/**
 * @param {string} locale Locale as a string (ll-RR)
 * @param {string} url A path to another page
 * @return {string} Path with locale prepended (or replaced)
 */
function localizeURL(locale, url) {
  const languageString = locales.join('|');
  const urlRE = new RegExp(`(?:/(?:${languageString}))?(/.*)`);
  return url.replace(urlRE, `/${locale}$1`);
}

/**
 * @return {JSX.Element} ...
 */
export function LanguageDetector() {
  const { i18n } = useTranslation();

  if (!i18n) {
    return null;
  }

  const validLocale = determineKnownLocale(i18n.language);
  i18n.changeLanguage(validLocale);
  const localizedURL = localizeURL(validLocale, window.location.pathname);

  return <Navigate to={`${localizedURL}home`} />;
}
